
.DisruptionDetails {
  color: #000;
  background-color: #fff;
  padding: 5px;
  padding: 20px;
}

.DisruptionDetails .PreWrap {
  white-space: pre-line;
  margin-top: 20px;
}
