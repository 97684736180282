.BusDetailsBubble .ValueText {
  font-size: 14px;
  margin-bottom: 8px;
  color: black;
}
.BusDetailsBubble .HeadingText {
  font-weight: 400;
  color: gray;
  font-size: 14px;
  line-height: 20px;
}
.BusDetailsBubble .Body {
  background-color: white;
  padding: 10px 20px;
}
