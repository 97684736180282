@font-face {
    font-family: "Fira sans";
    src: url("./fonts/FiraSans/FiraSans-Regular.eot");
    src: url("./fonts/FiraSans/FiraSans-Regular.eot?#iefix") format('embedded-opentype'),
         url("./fonts/FiraSans/FiraSans-Regular.woff2") format('woff2'),
         url("./fonts/FiraSans/FiraSans-Regular.woff") format('woff'),
         url("./fonts/FiraSans/FiraSans-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Fira sans";
    src: url("./fonts/FiraSans/FiraSans-Medium.eot");
    src: url("./fonts/FiraSans/FiraSans-Medium.eot?#iefix") format('embedded-opentype'),
         url("./fonts/FiraSans/FiraSans-Medium.woff2") format('woff2'),
         url("./fonts/FiraSans/FiraSans-Medium.woff") format('woff'),
         url("./fonts/FiraSans/FiraSans-Medium.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Fira sans";
    src: url("./fonts/FiraSans/FiraSans-SemiBold.eot");
    src: url("./fonts/FiraSans/FiraSans-SemiBold.eot?#iefix") format('embedded-opentype'),
         url("./fonts/FiraSans/FiraSans-SemiBold.woff2") format('woff2'),
         url("./fonts/FiraSans/FiraSans-SemiBold.woff") format('woff'),
         url("./fonts/FiraSans/FiraSans-SemiBold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
}

body {
  margin: 0;
  font-family: "Fira sans", helvetica;
}
.AppBase {
  padding-left: 92px;
  overflow: hidden;
}

.ColorWhite {
  color: white;
}
.ColorBlack {
  color: black;
}
.ColorGrey {
  color:#808080;
}
.Bold {
  font-weight: 700;
}

.NoGutterBottom {
  margin-bottom: 0;
}

@keyframes AnimEnter {
  0% {
    right: -20px;
  }
  100% {
    right: 10px;
  }
}

.Bubble {
  animation: AnimEnter 0.4s linear;
  background-color: rgb(21,32,66);
  border: 1px solid rgb(21,32,66);
  right: 10px;
  position: absolute;
  top: 10px;
  min-width: 320px;
  max-width: 400px;
  color: white;
  border-radius: 4px;
  max-height: 78%;
  overflow: auto;
}

.BubbleHeader {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.BubbleHeader.--Small {
  padding: 10px 20px;
}

.BubbleGraphics {
  margin-right: 12px;
}

.BubbleGraphics svg {
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

.BubbleTitle {
  color: #fff;
  font-size: 18px;
}

.BtnReset {
  background: none;
  background-color: unset !important;
  border: none;
  box-shadow: none !important;
  opacity: 0.6;
}

.Inline {
  display: inline-block;
}

.BtnReset:HOVER {
  opacity: 1;
}

.Pill {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  background-color: #4252a9;
  padding: 3px 6px 2px 8px;
  border-radius: 4px;
  color: #fff;
}

.PillDivider {
  display: inline-block;
  margin: 0 8px;
}

.Btn {
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background-color: rgb(66,82,169);
  border-radius: 4px;
  display: block;
  height: 40px;
}

.Btn:disabled {
  color: rgba(0,0,0,0.4);
}