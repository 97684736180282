.SearchContainer {
  position: absolute;
  top: 0;
  width: 620px;
  height: 60px;
  display: flex;
  flex-direction: column;
}

.SearchContainer.active {
  height: 140px;
}

.SearchContainer:HOVER .SearchButtonsContainer{
  display: inline-flex;
}

 .SearchContainer.active .SearchButtonsContainer{
  display: inline-flex;
 }

.SearchContainer.active .SearchButtonsContainer{
  border-radius: 0;
}

.SearchContainer .SearchButtonsContainer {
  background-color: rgb(21,32,66);
  border-left: 1px solid rgba(0,0,0,0.4);
  border-radius: 0 0 10px 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 0 20px 0 62px;
  display: none;
}

.SearchContainer .SearchIcon {
  background-color: rgb(21,32,66);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  left: 8px;
  top: 5px;
  border-radius: 4px;
  padding: 0;
  width: 50px;
  height: 50px;
}

.SearchContainer .SearchIcon svg {
  width: 40px;
  vertical-align: middle;
}

.SearchContainer .SearchIcon svg .FillColor {
  fill: #fff;
}

.SearchButtonsContainer .Button {
  background-color: rgb(66,82,169);
  margin-left: 3px;
  height: 32px;
}

.SearchButtonsContainer .ButtonImg {
  width: 100px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 3px;
}

.SearchInputsContainer {
  border: 1px solid rgba(0,0,0,0.5);
  border-left-width: 0; 
  flex-grow: 1;
  background-color: #253668;
  height: 80px;
  border-radius: 0 0 10px 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
}

.SearchContainer.active .SearchInputsContainer{
  display: flex;
}

.VehicleSelectBtn {
  margin: 2px;
}

.VehicleSelectMenu {
  max-height: 250px;
  min-width: 300px;
  overflow-y: auto;
}

.SearchContainer .SearchPerformSearch {
  margin-left: 20px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  min-width: 90px;
}

.SearchContainer .SearchPerformSearch:disabled {
  color: rgba(0,0,0,0.4);
  cursor: default;
}

.SearchContainer .FormRow{
  display: flex;
  align-items: center;
}

.SearchInputBox {
  margin-left: 14px;
}
