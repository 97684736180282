.Legend {
  position: absolute;
  left: 92px;
  border-radius: 0 10px 0 0;
  background-color: rgba(21,32,66,0.9);
  bottom: 0;
  padding: 4px 32px 4px 50px;
  display: flex;
  flex-direction: row;
}

.Legend.Closed {
  cursor: pointer;
  left: 140px;
  bottom: 20px;
  height: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 50px;
  padding: 0;
}

.Legend.Closed:hover {
  background-color: #2b3570;
}

.Legend .HideBtn {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #4252a9;
  height: 30px;
  width: 30px;
  border-radius: 0 10px 0 0;
}

.Legend .HideBtn:hover {
  background-color: #2b3570;
}

.Legend .GroupContainer {
  display: flex;
  border-right: 1px solid rgba(0,0,0,0.4);
  padding-right: 16px;
  margin-right: 8px;
}

.Legend .GroupContainer .GroupsLeft {
  margin-right: 10px;
}

.Legend .Group, .Legend .Type {
  height: 19px;
  margin: 8px 0;
  cursor: pointer;
  color: grey;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.Type {
  cursor: default !important;
}

.Group {
  margin-right: 4px !important;
  white-space: nowrap;
}

.Legend .Group:hover, .Legend .Type:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.Legend .Group.Selected, .Legend .Type.Selected {
  color: white;
}

.Legend .Group.Selected:hover, .Legend .Type.Selected:hover {
  background-color: rgba(200, 200, 255, 0.4);
}

.Legend .Group .ColorSquare, .Legend .Type .Icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Legend .Type {
  margin-left: 8px;
}

