@charset "UTF-8";

.BookingListContainer {
  color: black;
  border-radius: 0 0 4px 4px;
  background-color: white;
  width: 100%;
  overflow-y: scroll;
}

.BookingListItem {
  padding: 12px 18px;
  display: flex;
}

.CapacityIcon {
  width: 34px;
  height: 34px;
}

.CapacityIcon .FillColor {
  fill: #fff;
}
.CapacityIcon .StrokeColor {
  stroke: #fff;
}

.BookingListItem:nth-child(even) {
  background-color: #f2f2f5;
}

.BookingListItem .BookingNumber,
.BookingListItem .BookingCapacity,
.BookingListItem .BookingInfo{
  display: flex;
  justify-content: center;
}

.BookingListItem .BookingInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 6px;
  overflow: hidden;
}

.BookingListItem .BookingNumber {
align-items: center;
}

.BookingListItem .BookingCapacity {
  padding-left: 8px;
  display: flex;
  align-items: center;
}

.BookingListItem .BookingCapacity .BookingCapacityIcon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.38;
  width: 22px;
  height: 22px;
}

.BookingListItem .Address {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18px;
}

.BookingListItem .Assignment {
  font-size: 12px;
}

.EmptyBooking {
  border-radius: 0 0 16px 16px;
  background-color: #253668;
  padding: 22px 0 32px;
  text-align: center;
  color: #fff;
}

.EmptyGraphics {
  width: 72px;
  margin-bottom: 8px;
  opacity: 0.5;
}

.EmptyTextTitle {
  font-size: 18px;
  margin-bottom: 8px;
}

.EmptyTextSubtitle {
  font-size: 14px;
  opacity: 0.6;
}
