@charset "UTF-8";

.BookingNumberBox {
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 4px;
  background-color: #0F80E8;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
}