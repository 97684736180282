@charset "UTF-8";

.VehicleDetailsBubble .VehicleIdText {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 12px;
}

.VehicleDetailsBubble .VehicleIdText .VehicleIdTextLeft {
  line-height: 24px;
}
.VehicleDetailsBubble .VehicleIdText .VehicleIdTextRight {
  font-weight: 400;
  font-size: 14px;
  color: gray;
  float: right;
  line-height: 24px;
}

.VehicleDetailsBubble .BookingInfo {
}

.VehicleDetailsBubble .CapacityText {
  opacity: 0.62;
  font-size: 16px;
  margin-bottom: 2px;
}

.VehicleDetailsBubble .CapacityCell {
  display: inline-flex;
  position: relative;
  width: 60px;
  height: 48px;
  align-items: center;
  margin-right: 4px;
}

.VehicleDetailsBubble .VehicleInformation {
  padding: 16px 20px;
}

.VehicleDetailsBubble .CapacityBadge {
  position: absolute;
  display: grid;
  justify-content: center;
  align-content: center;
  height: 24px;
  min-width: 24px;
  border-radius: 12px;
  left: -8px;
  bottom: -3px;
  background-color: rgb(21,32,66);
  border: 1px solid white;
  font-size: 12px;
  pointer-events: none;
}
