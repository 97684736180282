.AddressVehicleDetailsBubble {
    left: 102px;
    top: 150px; 
    right: unset;
}

.LocationCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
}

.LocationCell svg {
    width: 18px;
    height: 46px;
    margin-right: 12px;
}

.AddressVehicleDetailsBubble .Content {
    flex: 1;
    position: relative;
    background-color: white;
    border-radius: 0 0 4px 4px;
    color: #000;
}

.AddressVehicleDetailsBubbleInfo {
    flex-direction: column;
    padding: 8px 20px;
}

.AddressVehicleDetailsBubbleInfo .Info {
    display: flex;
    flex-direction: row;
    padding: 2px 0;
}

.AddressVehicleDetailsBubbleInfo .Icon {
    display: flex;
    align-items: center;
}

.AddressVehicleDetailsBubbleInfo .Icon svg {
    fill: #4252a9 !important;
}

.AddressVehicleDetailsBubbleInfo .Info .Text {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
}

.AddressVehicleDetailsBubbleInfo .Info .Text > * {
    padding-top: 5px;
    padding-bottom: 5px;
}