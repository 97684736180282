@charset "UTF-8";

.LeftPanel {
  background-color: rgb(21,32,66);
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 92px;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}
.LeftPanel .NTDLogo {
  background-image: url('../img/logo.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 52px;
  height: 60px;
}
.LeftPanel .ButtonContainer {
  height: 250px;
  color: white;
  flex: 1;
}

.LeftPanel .LeftPanelButton {
  cursor: pointer;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid rgba(0,0,0,0.2);
}

.LeftPanel .LeftPanelButton:HOVER {
  background-color: rgb(113, 122, 172) !important;
}

.LeftPanel .LeftPanelButton.active {
  background-color: rgb(66,82,169);
}

.LeftPanel .LeftPanelButton.active .ButtonNumber {
  background-color: #fff;
  color: rgb(66,82,169);
}

.LeftPanel .ButtonNumber {
  background-color: rgb(66,82,169);
  border-radius: 3px;
  color:#fff;
  height: 22px; 
  font-size: 14px;
  margin-bottom: 12px; 
  padding: 3px;
  text-align: center;
  width: 50px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LeftPanel .ButtonName {
  font-size: 12px;
}

.LeftPanel .ClockContainer {
  background-color: rgb(66,82,169);
  border-radius: 10px;
  bottom: 10px;
  color: white;
  height: 70px;
  left: 10px;
  position: absolute;
  text-align: center;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
}

.LeftPanel .ResetButton {
  color: white;
}

.LeftPanel .ResetButton .ButtonName {
  margin-top: 12px;
}

.LeftPanel .ClockContainer .ClockTime {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 6px;
}

.LeftPanel .ClockContainer .ClockDate {
  font-size: 16px;
  opacity: 0.5;
  font-weight: 400;
}